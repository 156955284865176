import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import { Card, CardContent, Button, Grid, ButtonGroup, Input, InputAdornment, Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core';
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        fontSize: "15px",
        maxWidth: "700px",
        '& h2': {
            fontSize: "30px",
        }
    },
    MuiButtonLabel: {
        textTransform: "initial",
    },
    spaceTop: {
        paddingTop: "25px!important",
    },
    topShape: {
        width: "100px",
        height: "100px",
        mixBlendMode: "difference",
        backgroundColor: "black",
        position: "fixed",  
        zIndex: "50",
    }
});

const ResistorESeriesPage = ({ data, location }) => {
    const [inputValue, setInputValue] = useState(123);
    const [eSeriesIndex, setESeriesIndex] = useState(2);
    const [valueTypeIndex, setValueTypeIndex] = useState(0);
    const [prefixTypesIndex, setPrefixTypesIndex] = useState(5);
    const classes = useStyles();
    const title = "E-Reihen Widerstand bestimmen";

    const prefixTypes = [
        { name: "p", potenz: "-12" },
        { name: "n", potenz: "-9" },
        { name: "u", potenz: "-6" },
        { name: "m", potenz: "-3" },
        { name: " ", potenz: "0" },
        { name: "k", potenz: "3" },
        { name: "M", potenz: "6" },
        { name: "G", potenz: "9" },
        { name: "T", potenz: "12" },
    ]

    const valueTypes = [
        { name: "Widerstand", unit: "R" },
        { name: "Kondensator", unit: "F" },
        { name: "Spule", unit: "H" }
    ]

    const eSeries = [
        { title: "E3", values: [1.0, 2.2, 4.7] },
        { title: "E6", values: [1.0, 1.5, 2.2, 3.3, 4.7, 6.8] },
        { title: "E12", values: [1.0, 1.2, 1.5, 1.8, 2.2, 2.7, 3.3, 3.9, 4.7, 5.6, 6.8, 8.2] },
        { title: "E24", values: [1.0, 1.1, 1.2, 1.3, 1.5, 1.6, 1.8, 2.0, 2.2, 2.4, 2.7, 3.0, 3.3, 3.6, 3.9, 4.3, 4.7, 5.1, 5.6, 6.2, 6.8, 7.5, 8.2, 9.1] },
        { title: "E48", values: [1.00, 1.05, 1.10, 1.15, 1.21, 1.27, 1.33, 1.40, 1.47, 1.54, 1.62, 1.69, 1.78, 1.87, 1.96, 2.05, 2.15, 2.26, 2.37, 2.49, 2.61, 2.74, 2.87, 3.01, 3.16, 3.32, 3.48, 3.65, 3.83, 4.02, 4.22, 4.42, 4.64, 4.87, 5.11, 5.36, 5.62, 5.90, 6.19, 6.49, 6.81, 7.15, 7.50, 7.87, 8.25, 8.66, 9.09, 9.53] },
        { title: "E96", values: [1.00, 1.02, 1.05, 1.07, 1.10, 1.13, 1.15, 1.18, 1.21, 1.24, 1.27, 1.30, 1.33, 1.37, 1.40, 1.43, 1.47, 1.50, 1.54, 1.58, 1.62, 1.65, 1.69, 1.74, 1.78, 1.82, 1.87, 1.91, 1.96, 2.00, 2.05, 2.10, 2.15, 2.21, 2.26, 2.32, 2.37, 2.43, 2.49, 2.55, 2.61, 2.67, 2.74, 2.80, 2.87, 2.94, 3.01, 3.09, 3.16, 3.24, 3.32, 3.40, 3.48, 3.57, 3.65, 3.74, 3.83, 3.92, 4.02, 4.12, 4.22, 4.32, 4.42, 4.53, 4.64, 4.75, 4.87, 4.99, 5.11, 5.23, 5.36, 5.49, 5.62, 5.76, 5.90, 6.04, 6.19, 6.34, 6.49, 6.65, 6.81, 6.98, 7.15, 7.32, 7.50, 7.68, 7.87, 8.06, 8.25, 8.45, 8.66, 8.87, 9.09, 9.31, 9.53, 9.76] },
        { title: "E192", values: [1.00, 1.01, 1.02, 1.04, 1.05, 1.06, 1.07, 1.09, 1.10, 1.11, 1.13, 1.14, 1.15, 1.17, 1.18, 1.20, 1.21, 1.23, 1.24, 1.26, 1.27, 1.29, 1.30, 1.32, 1.33, 1.35, 1.37, 1.38, 1.40, 1.42, 1.43, 1.45, 1.47, 1.49, 1.50, 1.52, 1.54, 1.56, 1.58, 1.60, 1.62, 1.64, 1.65, 1.67, 1.69, 1.72, 1.74, 1.76, 1.78, 1.80, 1.82, 1.84, 1.87, 1.89, 1.91, 1.93, 1.96, 1.98, 2.00, 2.03, 2.05, 2.08, 2.10, 2.13, 2.15, 2.18, 2.21, 2.23, 2.26, 2.29, 2.32, 2.34, 2.37, 2.40, 2.43, 2.46, 2.49, 2.52, 2.55, 2.58, 2.61, 2.64, 2.67, 2.71, 2.74, 2.77, 2.80, 2.84, 2.87, 2.91, 2.94, 2.98, 3.01, 3.05, 3.09, 3.12, 3.16, 3.20, 3.24, 3.28, 3.32, 3.36, 3.40, 3.44, 3.48, 3.52, 3.57, 3.61, 3.65, 3.70, 3.74, 3.79, 3.83, 3.88, 3.92, 3.97, 4.02, 4.07, 4.12, 4.17, 4.22, 4.27, 4.32, 4.37, 4.42, 4.48, 4.53, 4.59, 4.64, 4.70, 4.75, 4.81, 4.87, 4.93, 4.99, 5.05, 5.11, 5.17, 5.23, 5.30, 5.36, 5.42, 5.49, 5.56, 5.62, 5.69, 5.76, 5.83, 5.90, 5.97, 6.04, 6.12, 6.19, 6.26, 6.34, 6.42, 6.49, 6.57, 6.65, 6.73, 6.81, 6.90, 6.98, 7.06, 7.15, 7.23, 7.32, 7.41, 7.50, 7.59, 7.68, 7.77, 7.87, 7.96, 8.06, 8.16, 8.25, 8.35, 8.45, 8.56, 8.66, 8.76, 8.87, 8.98, 9.09, 9.20, 9.31, 9.42, 9.53, 9.65, 9.76, 9.88] }
    ]

    var resultArray = [];
    let targetValue = inputValue;
    let potentenz = 0;
    while(targetValue > 10)
    {
        targetValue = targetValue / 10;
        potentenz++;
    }

    const values = eSeries[eSeriesIndex].values;
    if(values.indexOf(targetValue) !== -1)
    {
        resultArray = [{ description: "Exakt", seriesValue: inputValue, error: 0 }]
    }
    else
    {
        let data = eSeries[eSeriesIndex].values;
        let vaLower = data.reduce((accu, curVal) => {
            if(targetValue > curVal)
            {
                return curVal;
            }
            return accu;
        });

        let index = data.indexOf(vaLower);
        let vaHigher = (index+1 >= data.length) ? 10.0 : data[index+1];

        const zehnerPotentz = (zahl, potentz) => {return zahl * (10 ** potentz)};

        resultArray = [
            { description: "Größer", seriesValue: zehnerPotentz(vaHigher,potentenz), error: (((vaHigher/targetValue)*100)-100) },
            { description: "Kleiner", seriesValue: zehnerPotentz(vaLower, potentenz), error: ((targetValue/vaLower)*100-100)*-1 }]
    }

    return (
        <Layout location={location} title={title}>
            <SEO title={title} />
            <h1>Widerstand, Kondensator, Spule, Zener-Diode E-Reihe bestimmen</h1>
            <p>Die E Reihe ist eine nach DIN IEC 60063 genormte Folge von Eigenschaftswerten elektrischer Bauelementen. Die Werte ergeben sich aus der sog. Renard-Serie. Die im Handel erhältlichen Widerstände, Kondensatoren, Spulen oder Zener-Dioden orientieren sich an diesen E-Reihen. In der Norm sind die E-Reihen wie folgt definiert: E3, E6, E12, E24, E48, E96 und E192.</p>
            <p>Mit diesem Webtool können Sie den nächsten Wert zu einem gewünschten Wert einer E-Reihe ermitteln. Es wird zusätzlich die Abweichung in prozent ermittelt.</p>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} spacing={2} className={classes.spaceTop}>
                            <Grid item xs={12} md={12}>
                                <h2>E-Reihe Bestimmen Web Tool</h2>                                
                                <h3>Eingabe</h3>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} md={2}>
                                E-Reihe
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <ButtonGroup orientation="horizontal" disableElevation color="secondary">
                                    {
                                        eSeries.map((eSerie, index) => (
                                            <Button variant={(eSeriesIndex == index) ? "contained" : ""} onClick={() => setESeriesIndex(index)}>{eSerie.title}</Button>
                                        ))}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} md={2}>
                                Bauelement
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <ButtonGroup orientation="horizontal" disableElevation color="secondary">
                                    {
                                        valueTypes.map((type, index) => (
                                            <Button variant={(valueTypeIndex == index) ? "contained" : ""} onClick={() => setValueTypeIndex(index)}>{type.name}</Button>
                                        ))}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} md={2}>
                                Prefix
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <ButtonGroup orientation="horizontal" disableElevation color="secondary">
                                    {
                                        prefixTypes.map((type, index) => (
                                            <Button classes={{label: classes.MuiButtonLabel}} variant={(prefixTypesIndex == index) ? "contained" : ""} onClick={() => setPrefixTypesIndex(index)}>{type.name}</Button>
                                        ))}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} md={2}>
                                Zielwert
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Input id="standard-basic" label="Standard" endAdornment={<InputAdornment position="end">{prefixTypes[prefixTypesIndex].name}{valueTypes[valueTypeIndex].unit}</InputAdornment>} value={inputValue}
                                    onChange={(event) => setInputValue(parseInt(event.target.value))} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={0}  className={classes.spaceTop}>
                            <Grid item xs={12} md={2}>
                                <h3>Ausgabe</h3>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Wert in {eSeries[eSeriesIndex].title}</TableCell>
                                            <TableCell>Abweichung</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultArray.map((data, index) => (
                                            <TableRow>
                                                <TableCell>{data.description}</TableCell>
                                        <TableCell>{data.seriesValue.toFixed(0)} {prefixTypes[prefixTypesIndex].name}{valueTypes[valueTypeIndex].unit}</TableCell>
                                                <TableCell>{data.error.toFixed(1)} %</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br/>
            <br/>
            <br/>
            <h1>E-Reihen Werte für E3, E6, E12, E24, E48, E96, E192</h1>
            {eSeries.map((serie, index) => (
                <>
                    <h3>{serie.title}</h3>
                    <p>
                        {serie.values.map(value => {return (" "+value)}).toString()}
                    </p>
                </>
            ))}
            <br/>
            <note>Hinweis: Ich übernehme keine Garantie für die Gültigkeit der Werte.</note>
        </Layout>
    )
}

export default ResistorESeriesPage